export const environment = {
  production: false,
  mock: false,
  buildNumber: '#{buildNumber}#',
  appInsights: {
    instrumentationKey: 'b3c2ae24-e898-4037-a5c0-dc2d6c496e71',
  },
  baseUrl: 'https://kiosk.checkin.testcloud.cae.com',
  azureAd: {
    tenant: '006c1e48-e342-47e9-ab5d-0dd9ff89bd96',
    clientId: 'd911cc18-f9f7-43fc-a304-c459e05311fb',
    redirectPath: 'login',
    scopes: ['api://d911cc18-f9f7-43fc-a304-c459e05311fb/Global.All'],
    loginUrl: null,
  },
  launchDarkly: {
    isEnabled: true,
    apiKey: '5c9c26af761af0081c8269d7',
  },
  store: {
    featureFlagsEnabled: true,
  },
  api: {
    baseUrl: 'https://api.testcloud.cae.com/trainingxpapi/v1',
  },
  heap: {
    apiKey: '1617734224',
  },
  idleTime: 60,
  idleTimeout: 30,
  refreshTimer: '30000',
  byPassCode: 'H3C0T9',
  documentScanner: {
    apidId: 'checkin',
    apiKey: 'checkin',
  },
};
